/* styles.css */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: #8a2be2;
  color: #fff;
  padding: 1rem 0;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link img {
  width: 24px;
  height: 24px;
}

.main {
  padding: 2rem 0;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
}

.hero-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.cta-button {
  background-color: #8a2be2;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 5px;
}

.ticker {
  background-color: #000;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.ticker-item {
  display: inline-block;
  padding: 0 1rem;
  font-size: 2rem;
}

.nav-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 2rem;
}

.nav-item {
  margin: 0.5rem;
  font-size: 1.2rem;
}

.tokenomics {
  text-align: center;
  margin-bottom: 2rem;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.separator {
  font-size: 1.5rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.feature-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.footer {
  background-color: #f8f8f8;
  text-align: center;
  padding: 1rem 0;
  margin-top: 2rem;
}

.footer-text {
  font-size: 0.9rem;
  color: #666;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
  .hero {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .hero-image {
    max-width: 50%;
    margin-right: 2rem;
  }

  .hero-content {
    flex: 1;
  }

  .hero-title {
    font-size: 4rem;
  }

  .hero-description {
    font-size: 1.5rem;
  }

  .nav-item {
    font-size: 1.5rem;
  }

  .section-title {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .hero-title {
    font-size: 5rem;
  }

  .features {
    grid-template-columns: repeat(3, 1fr);
  }
}