* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #333;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  color: #fff;
  background-color: #8a2be2;
  padding: 1rem 0;
}

.header .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
}

.social-links {
  gap: 1rem;
  display: flex;
}

.social-link img {
  width: 24px;
  height: 24px;
}

.main {
  padding: 2rem 0;
}

.hero {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.hero-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.hero-title {
  margin-bottom: 1rem;
  font-size: 3rem;
}

.hero-description {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.cta-button {
  color: #fff;
  cursor: pointer;
  background-color: #8a2be2;
  border: none;
  border-radius: 5px;
  padding: .75rem 1.5rem;
  font-size: 1.1rem;
}

.ticker {
  color: #fff;
  white-space: nowrap;
  background-color: #000;
  margin-bottom: 2rem;
  padding: 1rem 0;
  overflow: hidden;
}

.ticker-item {
  padding: 0 1rem;
  font-size: 2rem;
  display: inline-block;
}

.nav-list {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  list-style-type: none;
  display: flex;
}

.nav-item {
  margin: .5rem;
  font-size: 1.2rem;
}

.tokenomics {
  text-align: center;
  margin-bottom: 2rem;
}

.section-title {
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.separator {
  font-size: 1.5rem;
}

.features {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
  display: grid;
}

.feature-card {
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px #0000001a;
}

.feature-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.footer {
  text-align: center;
  background-color: #f8f8f8;
  margin-top: 2rem;
  padding: 1rem 0;
}

.footer-text {
  color: #666;
  font-size: .9rem;
}

@media (width >= 768px) {
  .hero {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }

  .hero-image {
    max-width: 50%;
    margin-right: 2rem;
  }

  .hero-content {
    flex: 1;
  }

  .hero-title {
    font-size: 4rem;
  }

  .hero-description, .nav-item {
    font-size: 1.5rem;
  }

  .section-title {
    font-size: 3rem;
  }
}

@media (width >= 1024px) {
  .hero-title {
    font-size: 5rem;
  }

  .features {
    grid-template-columns: repeat(3, 1fr);
  }
}
/*# sourceMappingURL=index.380d664a.css.map */
